/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
/*@import "~font-awesome-animation/dist/font-awesome-animation.css";*/

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0px 0px 7px rgba(0, 0, 0, 0.8);
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(128,128,128,0.5); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(128,128,128,0.8);  
}

.bg-black {
    background-color: black;
}

.bg-white-20 {
    background-color: #333333 !important;
}

.bg-dark-1 {
    background-color: #363d44 !important;
}

.bg-desktop {
    background-color: #1B2638;
}

.fa-fit > svg {
    width: 100% !important;
}

.border-transparent:not(.border-primary) {
    border-color: transparent !important;
}

.flex-fill {
    min-height: 0;
}

.auto-size-input {
    display: inline-grid;
    align-items: stretch;
}

.auto-size-input textarea {
    grid-area: 2/1;
}

.scroll-container {
    transform: rotate(180deg);
    /*direction: rtl;*/
}

.scroll-container .scroll-content {
    transform: rotate(180deg);
}

.toast {
    width: 400px;
}